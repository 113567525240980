import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Commission {
  getCommissionList = (payload = {}) => {
    const url = `${API_URL}api/v1/account/admin/commissions/?limit=${
      payload?.limit || 50
    }&offset=${payload?.offset || 0}&name=${payload?.name || ""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getCommissionDetails = (id) => {
    const url = `${API_URL}api/v1/account/admin/commissions/${id}/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  changeStatus = (payload, id) => {
    const url = `${API_URL}api/v1/account/admin/commissions/${id}/`;
    return axios
      .patch(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
