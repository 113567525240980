import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Messages {
  getAllConversations = () => {
    return axios
      .get(`${API_URL}api/v1/chat/conversation/`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getConversationDetail = (id) => {
    return axios
      .get(`${API_URL}api/v1/chat/conversation/${id}`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  getConversationMessages = (id) => {
    return axios
      .get(`${API_URL}api/v1/chat/message/${id}/`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  createConversation = (payload = {}) => {
    const url = `${API_URL}api/v1/chat/conversation/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  deleteConversation = (id) => {
    const url = `${API_URL}api/v1/chat/conversation/${id}/`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
