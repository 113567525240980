/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, Popover } from "antd";
import {
  AlignRightOutlined,
  AlignLeftOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Spinner from "../common/spinner";
import SiderBar from "./sidebar";

import { setUserData } from "../../utils/redux/actions";

import "./styles.scss";

const Dashboard = React.lazy(() => import("../dashboard"));
const Reports = React.lazy(() => import("../reports"));
const Profile = React.lazy(() => import("../admin/profile"));
const ChangePassword = React.lazy(() => import("../admin/password"));
const OrderList = React.lazy(() => import("../orders/order/order-list"));
const AddOrder = React.lazy(() => import("../orders/order/add-order"));
const OrderDetails = React.lazy(() => import("../orders/order/order-details"));
const ReturnList = React.lazy(() => import("../orders/return/return-list"));
const AddReturn = React.lazy(() => import("../orders/return/add-return"));
const CancelList = React.lazy(() => import("../orders/cancel/cancel-list"));
const AddCancel = React.lazy(() => import("../orders/cancel/add-cancel"));
const ReasonList = React.lazy(() => import("../orders/reason/reason-list"));
const AddReason = React.lazy(() => import("../orders/reason/add-reason"));
const SendNotification = React.lazy(() =>
  import("../notifications/send-notification")
);
const GlobalSettings = React.lazy(() => import("../settings/global-settings"));
const AppSettings = React.lazy(() => import("../settings/app-settings"));
const BannerList = React.lazy(() =>
  import("../settings/banner-settings/banner-list")
);
const AddBanner = React.lazy(() =>
  import("../settings/banner-settings/add-banner")
);
const StaffList = React.lazy(() =>
  import("../settings/staff-settings/staff-list")
);
const AddStaff = React.lazy(() =>
  import("../settings/staff-settings/add-staff")
);
const StaffDetails = React.lazy(() =>
  import("../settings/staff-settings/staff-details")
);
const CustomerList = React.lazy(() => import("../customers/customer-list"));
const AddCustomer = React.lazy(() => import("../customers/add-customer"));
const CustomerDetails = React.lazy(() =>
  import("../customers/customer-details")
);
const AttributeList = React.lazy(() =>
  import("../inventory/attributes/attribute-list")
);
const AddAttribute = React.lazy(() =>
  import("../inventory/attributes/add-attribute")
);
const BrandList = React.lazy(() => import("../inventory/brands/brand-list"));
const AddBrand = React.lazy(() => import("../inventory/brands/add-brand"));
const CategoryList = React.lazy(() =>
  import("../inventory/categories/categories-list")
);
const AddCategory = React.lazy(() =>
  import("../inventory/categories/add-categories")
);
const ProductList = React.lazy(() =>
  import("../inventory/products/product-list")
);
const AddProduct = React.lazy(() =>
  import("../inventory/products/add-product")
);
const CouponList = React.lazy(() =>
  import("../inventory/coupons/coupons-list")
);
const AddCoupon = React.lazy(() => import("../inventory/coupons/add-coupon"));
const DealsList = React.lazy(() => import("../inventory/deals/deals-list"));
const AddDeal = React.lazy(() => import("../inventory/deals/add-deal"));
const ReviewsList = React.lazy(() =>
  import("../inventory/reviews/reviews-list")
);
const StoreList = React.lazy(() => import("../store/store-list"));
const AddStore = React.lazy(() => import("../store/add-store"));
const StoreDetails = React.lazy(() => import("../store/store-details"));
const BankList = React.lazy(() => import("../payments/bank-info/bank-list"));
const AddBank = React.lazy(() => import("../payments/bank-info/add-bank"));
const BankDetails = React.lazy(() =>
  import("../payments/bank-info/bank-details")
);

const TransactionList = React.lazy(() =>
  import("../payments/transaction/transaction-list")
);
const CommissionList = React.lazy(() =>
  import("../payments/commission/commission-list")
);
const PayoutList = React.lazy(() => import("../payments/payout/payout-list"));
const AddPayout = React.lazy(() => import("../payments/payout/add-payout"));
const WalletRechargeList = React.lazy(() =>
  import("../payments/wallet-recharge/wallet-recharge-list")
);
const AddWalletRecharge = React.lazy(() =>
  import("../payments/wallet-recharge/add-wallet-recharge")
);
const MembershipList = React.lazy(() =>
  import("../membership/membership/membership-list")
);
const AddMembership = React.lazy(() =>
  import("../membership/membership/add-membership")
);
const AddPackage = React.lazy(() =>
  import("../membership/package/add-package")
);
const PackageList = React.lazy(() =>
  import("../membership/package/package-list")
);
const ShipmentList = React.lazy(() =>
  import("../delivery/shipment/shipment-list")
);
const AddShipment = React.lazy(() =>
  import("../delivery/shipment/add-shipment")
);
const DeliveryChargesList = React.lazy(() =>
  import("../delivery/delivery-charges/delivery-charges-list")
);
const DeliveryPartnerSettings = React.lazy(() =>
  import("../delivery/delivery-partners")
);
const DeliveryPartnerList = React.lazy(() =>
  import("../delivery/delivery-partners/delivery-partner-list")
);
const AddDeliveryPartnerList = React.lazy(() =>
  import("../delivery/delivery-partners/add-delivery-partner")
);
const AddDeliveryCharge = React.lazy(() =>
  import("../delivery/delivery-charges/add-delivery-charge")
);
const FaqList = React.lazy(() => import("../faq/faq-list"));
const AddFAQ = React.lazy(() => import("../faq/add-faq"));
const PrivacyPolicy = React.lazy(() =>
  import("../terms-and-conditions/privacy-policy")
);
const RefundPolicy = React.lazy(() =>
  import("../terms-and-conditions/refund-policy")
);
const ReturnPolicy = React.lazy(() =>
  import("../terms-and-conditions/return-policy")
);
const OrderCancelPolicy = React.lazy(() =>
  import("../terms-and-conditions/order-cancel-policy")
);
const Terms = React.lazy(() => import("../terms-and-conditions/terms"));
const AboutUs = React.lazy(() => import("../terms-and-conditions/about-us"));
const Messages = React.lazy(() => import("../messages"));

const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="profile-opt" onClick={handleProfileClick}>
          <Avatar size={20} src="https://joeschmoe.io/api/v1/random" />
          Profile
        </div>
        <div className="password-opt" onClick={handleChangeLogout}>
          <LoginOutlined />
          Change Password
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  const handleProfileClick = () => {
    navigate(`/admin/profile`);
  };

  const handleLogout = () => {
    localStorage.removeItem("tajira-admin-panel-user-data");
    dispatch(setUserData({}));
    navigate("/login");
  };

  return (
    <Layout>
      <SiderBar toggle={toggle} collapsed={collapsed} />
      <Layout className="site-layout">
        <Header className="site-layout-background bugify-navbar">
          <div className="collapse-btn" onClick={toggle}>
            {collapsed ? (
              <AlignRightOutlined className="trigger" />
            ) : (
              <AlignLeftOutlined className="trigger" />
            )}
          </div>
          <Space align="center" size={20}>
            <Popover
              placement="rightBottom"
              content={userPopover()}
              trigger="click"
              className="user-popover"
            >
              <Avatar shape="square" src="https://joeschmoe.io/api/v1/random" />
            </Popover>
          </Space>
        </Header>
        <Content className="site-layout-background content-main-section">
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route path="/reports" element={<Reports />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/admin/profile" element={<Profile />} />
              <Route
                path="/admin/change-password"
                element={<ChangePassword />}
              />
              <Route path="/orders/list-order" element={<OrderList />} />
              <Route path="/orders/add-order" element={<AddOrder />} />
              <Route path="/orders/edit-order/:id" element={<AddOrder />} />
              <Route
                path="/orders/order-details/:id"
                element={<OrderDetails />}
              />
              <Route path="/orders/list-cancel" element={<CancelList />} />
              <Route path="/orders/add-cancel" element={<AddCancel />} />
              <Route path="/orders/edit-cancel/:id" element={<AddCancel />} />
              <Route path="/orders/list-return" element={<ReturnList />} />
              <Route path="/orders/add-return" element={<AddReturn />} />
              <Route path="/orders/edit-return/:id" element={<AddReturn />} />
              <Route path="/orders/list-reason" element={<ReasonList />} />
              <Route path="/orders/add-reason" element={<AddReason />} />
              <Route path="/orders/edit-reason/:id" element={<AddReason />} />
              <Route
                path="/notification/send-notification"
                element={<SendNotification />}
              />
              <Route
                path="/settings/global-settings"
                element={<GlobalSettings />}
              />
              <Route path="/settings/app-settings" element={<AppSettings />} />
              <Route path="/settings/list-banner" element={<BannerList />} />
              <Route path="/settings/add-banner" element={<AddBanner />} />
              <Route path="/settings/edit-banner/:id" element={<AddBanner />} />
              <Route path="/settings/list-staff" element={<StaffList />} />
              <Route path="/settings/add-staff" element={<AddStaff />} />
              <Route path="/settings/edit-staff/:id" element={<AddStaff />} />
              <Route
                path="/settings/staff-details/:id"
                element={<StaffDetails />}
              />
              <Route
                path="/customer/list-customer"
                element={<CustomerList />}
              />
              <Route path="/customer/add-customer" element={<AddCustomer />} />
              <Route
                path="/customer/edit-customer/:id"
                element={<AddCustomer />}
              />
              <Route
                path="/customer/customer-details/:id"
                element={<CustomerDetails />}
              />
              <Route
                path="/inventory/list-attribute"
                element={<AttributeList />}
              />
              <Route
                path="/inventory/add-attribute"
                element={<AddAttribute />}
              />
              <Route
                path="/inventory/edit-attribute/:id"
                element={<AddAttribute />}
              />
              <Route path="/inventory/list-brand" element={<BrandList />} />
              <Route path="/inventory/add-brand" element={<AddBrand />} />
              <Route path="/inventory/edit-brand/:id" element={<AddBrand />} />
              <Route
                path="/inventory/list-category"
                element={<CategoryList />}
              />
              <Route path="/inventory/add-category" element={<AddCategory />} />
              <Route
                path="/inventory/edit-category/:id"
                element={<AddCategory />}
              />
              <Route path="/inventory/list-product" element={<ProductList />} />
              <Route path="/inventory/add-product" element={<AddProduct />} />
              <Route
                path="/inventory/edit-product/:id"
                element={<AddProduct />}
              />
              <Route path="/inventory/list-coupon" element={<CouponList />} />
              <Route path="/inventory/add-coupon" element={<AddCoupon />} />
              <Route
                path="/inventory/edit-coupon/:id"
                element={<AddCoupon />}
              />
              <Route path="/inventory/list-deal" element={<DealsList />} />
              <Route path="/inventory/add-deal" element={<AddDeal />} />
              <Route path="/inventory/edit-deal/:id" element={<AddDeal />} />
              <Route path="/inventory/list-reviews" element={<ReviewsList />} />
              <Route path="/store/list-store" element={<StoreList />} />
              <Route
                path="/store/store-details/:id"
                element={<StoreDetails />}
              />
              <Route path="/store/add-store" element={<AddStore />} />
              <Route path="/store/edit-store/:id" element={<AddStore />} />
              <Route path="/payments/list-banks-info" element={<BankList />} />
              <Route path="/payments/add-bank" element={<AddBank />} />
              <Route path="/payments/edit-bank/:id" element={<AddBank />} />
              <Route
                path="/payments/bank-details/:id"
                element={<BankDetails />}
              />
              <Route
                path="/payments/list-transaction"
                element={<TransactionList />}
              />
              <Route
                path="/payments/list-commission"
                element={<CommissionList />}
              />
              <Route path="/payments/list-payout" element={<PayoutList />} />
              <Route path="/payments/add-payout" element={<AddPayout />} />
              <Route path="/payments/edit-payout/:id" element={<AddPayout />} />
              <Route
                path="/payments/list-wallet-recharge"
                element={<WalletRechargeList />}
              />
              <Route
                path="/payments/add-wallet-recharge"
                element={<AddWalletRecharge />}
              />
              <Route
                path="/payments/edit-wallet-recharge/:id"
                element={<AddWalletRecharge />}
              />
              <Route
                path="/membership/list-membership"
                element={<MembershipList />}
              />
              <Route
                path="/membership/list-package"
                element={<PackageList />}
              />
              <Route path="/membership/add-package" element={<AddPackage />} />
              <Route
                path="/membership/edit-package/:id"
                element={<AddPackage />}
              />
              <Route
                path="/membership/list-membership"
                element={<MembershipList />}
              />
              <Route
                path="/membership/add-membership"
                element={<AddMembership />}
              />
              <Route
                path="/membership/edit-membership/:id"
                element={<AddMembership />}
              />
              <Route
                path="/delivery/list-shipment"
                element={<ShipmentList />}
              />
              <Route path="/delivery/add-shipment" element={<AddShipment />} />
              <Route
                path="/delivery/edit-shipment/:id"
                element={<AddShipment />}
              />
              <Route
                path="/delivery/list-delivery-charge"
                element={<DeliveryChargesList />}
              />
              <Route
                path="/delivery/add-delivery-charge"
                element={<AddDeliveryCharge />}
              />
              <Route
                path="/delivery/edit-delivery-charge/:id"
                element={<AddDeliveryCharge />}
              />
              <Route
                path="/delivery/delivery-partner-settings"
                element={<DeliveryPartnerSettings />}
              />
              <Route
                path="/delivery/delivery-partner-list/:id"
                element={<DeliveryPartnerList />}
              />
              <Route
                path="/delivery/add-delivery-partner"
                element={<AddDeliveryPartnerList />}
              />
              <Route
                path="/delivery/add-delivery-partner/:storeId"
                element={<AddDeliveryPartnerList />}
              />
              <Route
                path="/delivery/edit-delivery-partner/:id"
                element={<AddDeliveryPartnerList />}
              />
              <Route path="/faq/list-faq" element={<FaqList />} />
              <Route path="/faq/add-faq" element={<AddFAQ />} />
              <Route path="/faq/edit-faq/:id" element={<AddFAQ />} />
              <Route
                path="/terms-and-policies/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                path="/terms-and-policies/refund-policy"
                element={<RefundPolicy />}
              />
              <Route
                path="/terms-and-policies/return-policy"
                element={<ReturnPolicy />}
              />
              <Route
                path="/terms-and-policies/order-cancel-policy"
                element={<OrderCancelPolicy />}
              />
              <Route path="/terms-and-policies/terms" element={<Terms />} />
              <Route
                path="/terms-and-policies/about-us"
                element={<AboutUs />}
              />
              <Route path="/messages" element={<Messages />} />
            </Routes>
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
