import Auth from "./Auth";
import Faq from "./Faq";
import Terms from "./Terms";
import DeliveryCharges from "./DeliveryCharges";
import Shipment from "./Shipment";
import Membership from "./Membership";
import Package from "./Package";
import Wallet from "./Wallet";
import Payout from "./Payout";
import Commission from "./Commission";
import Transaction from "./Transaction";
import Bank from "./Bank";
import Store from "./Store";
import Reviews from "./Reviews";
import Products from "./Products";
import Coupons from "./Coupons";
import Categories from "./Categories";
import Brands from "./Brands";
import Deals from "./Deals";
import Attributes from "./Attributes";
import Customers from "./Customers";
import Notifications from "./Notifications";
import Global from "./Global";
import Staff from "./Staff";
import Banner from "./Banner";
import Order from "./Order";
import Cancel from "./Cancel";
import Return from "./Return";
import Reason from "./Reason";
import Dashboard from "./Dashboard";
import DeliveryPartners from "./DeliveryPartners";
import Messages from "./Messages";

const API = {
  Auth,
  DeliveryCharges,
  Terms,
  Shipment,
  Membership,
  Package,
  Faq,
  Wallet,
  Payout,
  Commission,
  Transaction,
  Bank,
  Store,
  Reviews,
  Products,
  Coupons,
  Categories,
  Brands,
  Deals,
  Attributes,
  Customers,
  Notifications,
  Global,
  Staff,
  Banner,
  Order,
  Cancel,
  Return,
  Reason,
  Dashboard,
  DeliveryPartners,
  Messages,
};

export default API;
