import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Products {
  getProductList = (payload) => {
    const url = `${API_URL}api/v1/store/admin/products/?limit=${
      payload?.limit || 50
    }&name=${payload?.name || ""}&offset=${payload?.offset || 0}&brand=${
      payload?.brand || ""
    }&category=${payload?.category || ""}&store=${payload?.store || ""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  getProductDetails = (id) => {
    const url = `${API_URL}api/v1/store/admin/products/${id}/`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  addProduct = (payload) => {
    const url = `${API_URL}api/v1/store/admin/products/`;
    return axios({
      url,
      method: "POST",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  editProduct = (payload, id) => {
    const url = `${API_URL}api/v1/store/admin/products/${id}/`;
    return axios({
      url,
      method: "PATCH",
      data: payload,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };

  deleteProduct = (id) => {
    const url = `${API_URL}api/v1/store/admin/products/${id}/`;
    return axios
      .delete(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
